<template>
    <div>
        <vx-tooltip :text="$t('apps.configurator.menu-button-tooltip')" position="top" delay=".3s" class="m-0">
            <vs-button type="border" class="mr-2 px-3 rounded-lg" @click="sendToMenuList" >
                <div class="flex flex-nowrap">
                    <feather-icon icon="MenuIcon" svgClasses="text-primary align-middle w-6" class="align-middle"/>
                </div>
            </vs-button>
        </vx-tooltip>
    </div>
</template>
<script>

export default {
    name:'configurator-menu-list-button',  
    props: {
        properties: {type: Object,required: true}
    },
    methods:{  
        sendToMenuList(){
            this.$router.push({name: 'Menu-List', params: {"versionUuid": this.properties.uuid}});
        }
    }  
}
</script>

<style scoped>

.action-button button {
    @apply h-12 !important;
}
</style>