<template>
    <div class="modalInfo">
        <vs-popup :active.sync="modalOpen" :title="''" classContent="modalInfo">
            <div class="containerPopUp">
                <div class="statusContainer">
                    <feather-icon v-if="this.type === 'success'" icon="CheckCircleIcon" svgClasses="text-success align-middle" class="align-middle"/>
                    <feather-icon v-else-if="this.type === 'warning'" icon="AlertTriangleIcon" svgClasses="text-warning align-middle" class="align-middle"/>
                    <feather-icon v-else-if="this.type === 'danger'" icon="AlertTriangleIcon" svgClasses="text-danger align-middle" class="align-middle"/>
                </div>
                <div class="mt-5 descriptionContainer">
                    <div class="headingContainer">
                        <h4>{{ this.headingText }}</h4>
                    </div>
                    <div class="mt-2 textContainer">
                        <p>{{ this.text }}</p>
                    </div>
                </div>
                <div class="mt-2 buttonContainer">
                    <vs-button type="filled" :color="this.type" class="delete-row rounded-lg px-3 py-2 mr-2 vs-button-text" @click="closeModal">Ok</vs-button>
                </div>
            </div>
        </vs-popup>
    </div>
</template>
<script>

export default {
  name: 'modal-info',
  props: {
    modalOpen: Boolean,
    type: String,
    headingText: String,
    text: String
  },
  methods: {
    closeModal() {
        this.toggle()
        this.$emit('closedModal')
    },
    toggle() {
        this.modalOpen = !this.modalOpen
    },
  },
  data() {
    return {}
  }
}
</script>
<style>
    .modalInfo .containerPopUp .statusContainer {
        display: flex;
        justify-content: center;
    }
    .modalInfo .containerPopUp .descriptionContainer {
        display: flex;
        flex-direction: column;
        text-align: center;
        white-space: pre-wrap;
    }
    .modalInfo .containerPopUp .buttonContainer {
        display: flex;
        justify-content: center;
    }
</style>