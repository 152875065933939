var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modalInfo" },
    [
      _c(
        "vs-popup",
        {
          attrs: {
            active: _vm.modalOpen,
            title: "",
            classContent: "modalInfo"
          },
          on: {
            "update:active": function($event) {
              _vm.modalOpen = $event
            }
          }
        },
        [
          _c("div", { staticClass: "containerPopUp" }, [
            _c(
              "div",
              { staticClass: "statusContainer" },
              [
                this.type === "success"
                  ? _c("feather-icon", {
                      staticClass: "align-middle",
                      attrs: {
                        icon: "CheckCircleIcon",
                        svgClasses: "text-success align-middle"
                      }
                    })
                  : this.type === "warning"
                  ? _c("feather-icon", {
                      staticClass: "align-middle",
                      attrs: {
                        icon: "AlertTriangleIcon",
                        svgClasses: "text-warning align-middle"
                      }
                    })
                  : this.type === "danger"
                  ? _c("feather-icon", {
                      staticClass: "align-middle",
                      attrs: {
                        icon: "AlertTriangleIcon",
                        svgClasses: "text-danger align-middle"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c("div", { staticClass: "mt-5 descriptionContainer" }, [
              _c("div", { staticClass: "headingContainer" }, [
                _c("h4", [_vm._v(_vm._s(this.headingText))])
              ]),
              _c("div", { staticClass: "mt-2 textContainer" }, [
                _c("p", [_vm._v(_vm._s(this.text))])
              ])
            ]),
            _c(
              "div",
              { staticClass: "mt-2 buttonContainer" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass:
                      "delete-row rounded-lg px-3 py-2 mr-2 vs-button-text",
                    attrs: { type: "filled", color: this.type },
                    on: { click: _vm.closeModal }
                  },
                  [_vm._v("Ok")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }