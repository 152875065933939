var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-tooltip",
        {
          staticClass: "m-0",
          attrs: {
            text: _vm.$t("apps.configurator.style-button-tooltip"),
            position: "top",
            delay: ".3s"
          }
        },
        [
          _c(
            "vs-button",
            {
              staticClass: "mr-2 px-3 rounded-lg",
              attrs: { type: "border" },
              on: { click: _vm.sendToStyleMenu }
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-nowrap" },
                [
                  _c("feather-icon", {
                    staticClass: "align-middle",
                    attrs: {
                      icon: "PenToolIcon",
                      svgClasses: "text-primary align-middle w-6"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }