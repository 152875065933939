<template>
    <div>
        <vx-tooltip :text="$t('apps.configurator.check-button-tooltip')" position="top" delay=".3s" class="m-0">
            <vs-button type="border" class="mr-2 px-3 rounded-lg" @click="checkStatusVersion">
                <div class="flex flex-nowrap">
                    <feather-icon icon="SearchIcon" svgClasses="text-primary align-middle w-6" class="align-middle"/>
                </div>
            </vs-button>
        </vx-tooltip>

        <ModalInfo 
            :modalOpen=modalOpen 
            :type=modalType 
            :headingText=headingModalText 
            :text=modalText 
            @closedModal="modalOpen = false"
        />
    </div>

</template>
<script>
import ConfiguratorVersionService from '@/modules/Apps/Services/Configurator/ConfiguratorVersionService'
import ModalInfo from '@/modules/Shared/Components/modal/ModalInfo.vue'

export default {
    name:'configurator-check-status-button',
    components: {
        ModalInfo
    },
    props: {
        properties:{type: Object,required: true}
    },
    data(){
        return {
            modalOpen: false,
            headingModalText: "",
            modalText: "",
            modalType: "",
        }  
    },   
    methods:{
        async checkStatusVersion() {
            try {
                this.$vs.loading()
                let response = await ConfiguratorVersionService.checkStatusVersion(this.properties.uuid)
                
                if (response.status_code == 200) {
                    if (response.description.status === 'ERROR') {
                        this.modalType = 'danger'
                        this.headingModalText = this.$t("apps.configurator.version.check.title.ko")
                        this.modalText = Object.entries(response.description.errors).reduce((acc, error) => {
                            return `${acc}\n ${error[0]}: ${error[1]}`
                        }, "")
                    } else {
                        this.modalType = "success"
                        this.headingModalText = this.$t("apps.configurator.version.check.title.ok")
                        this.modalText = this.$t("apps.configurator.version.check.text.ok")
                    }
                    this.modalOpen = true
                }
            } catch (error) {
                console.log('checkStatus error', error)
                this.$vs.notify({ text: this.$t("apps.configurator.version.check.error"), color: 'danger', position: 'top-right' })
            } finally {
                this.$vs.loading.close();
            }
        }     
    }
}
</script>

<style scoped>
.action-button button {
    @apply h-12 !important;
}
</style>
