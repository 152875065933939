import { render, staticRenderFns } from "./Configurator.vue?vue&type=template&id=74fb37b0&scoped=true&"
import script from "./Configurator.vue?vue&type=script&lang=js&"
export * from "./Configurator.vue?vue&type=script&lang=js&"
import style0 from "./Configurator.vue?vue&type=style&index=0&id=74fb37b0&scoped=true&lang=css&"
import style1 from "./Configurator.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74fb37b0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74fb37b0')) {
      api.createRecord('74fb37b0', component.options)
    } else {
      api.reload('74fb37b0', component.options)
    }
    module.hot.accept("./Configurator.vue?vue&type=template&id=74fb37b0&scoped=true&", function () {
      api.rerender('74fb37b0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/Apps/Pages/configurator/Configurator.vue"
export default component.exports