var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-tooltip",
        {
          staticClass: "m-0",
          attrs: {
            text: _vm.$t("apps.configurator.release-button-tooltip"),
            position: "top",
            delay: ".3s"
          }
        },
        [
          _c(
            "vs-button",
            {
              staticClass: "mr-2 px-3 rounded-lg",
              attrs: { type: "border" },
              on: { click: _vm.askReleaseVersion }
            },
            [
              _c("div", { staticClass: "flex flex-nowrap" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "22",
                      height: "22",
                      viewBox: "0 0 22 22",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c(
                      "g",
                      { attrs: { "clip-path": "url(#clip0_598_22207)" } },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M18.841 11.2757C21.0668 9.05143 20.2447 1.9444 20.2447 1.9444C20.2447 1.9444 13.1959 1.08934 10.9222 3.36146C8.64932 5.63437 4.41409 13.0299 4.41409 13.0299L9.16548 17.7783C9.16548 17.7783 16.6152 13.5015 18.841 11.2757Z",
                            stroke: "#3560DA",
                            "stroke-width": "1.86023",
                            "stroke-miterlimit": "10",
                            "stroke-linejoin": "round"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d:
                              "M13.125 9.07475C13.7809 9.73057 14.8442 9.73057 15.5 9.07475C16.1558 8.41893 16.1558 7.35563 15.5 6.6998C14.8442 6.04398 13.7808 6.04398 13.125 6.6998C12.4692 7.35563 12.4692 8.41893 13.125 9.07475Z",
                            fill: "#3560DA"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d:
                              "M7.9791 17.3869C6.49594 18.8701 4.01848 18.1762 4.01848 18.1762C4.01848 18.1762 3.34241 15.6904 4.8125 14.2203",
                            stroke: "#3560DA",
                            "stroke-width": "1.86023",
                            "stroke-miterlimit": "10"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d:
                              "M4.98859 12.4611L1.68501 11.8846L3.37963 9.3959C4.02737 8.44422 5.15914 7.93973 6.25911 8.11113L7.62415 8.32398",
                            stroke: "#3560DA",
                            "stroke-width": "1.86023",
                            "stroke-miterlimit": "10",
                            "stroke-linejoin": "round"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d:
                              "M9.73913 17.2109L10.3156 20.5145L12.8044 18.8199C13.756 18.1722 14.2605 17.0404 14.0891 15.9404L13.8763 14.5754",
                            stroke: "#3560DA",
                            "stroke-width": "1.86023",
                            "stroke-miterlimit": "10",
                            "stroke-linejoin": "round"
                          }
                        })
                      ]
                    ),
                    _c("defs", [
                      _c("clipPath", { attrs: { id: "clip0_598_22207" } }, [
                        _c("rect", {
                          attrs: { width: "22", height: "22", fill: "white" }
                        })
                      ])
                    ])
                  ]
                )
              ])
            ]
          )
        ],
        1
      ),
      _c("ModalInfo", {
        attrs: {
          modalOpen: _vm.modalOpen,
          type: _vm.modalType,
          headingText: _vm.headingModalText,
          text: _vm.modalText
        },
        on: {
          closedModal: function($event) {
            _vm.modalOpen = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }