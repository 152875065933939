<template>
    <div>
        <vx-tooltip :text="$t('apps.configurator.resources-button-tooltip')" position="top" delay=".3s" class="m-0">
            <vs-button  type="border" class="mr-2 px-3 rounded-lg" @click="sendToResources" >
                <div class="flex flex-nowrap">
                    <feather-icon icon="PaperclipIcon" svgClasses="text-primary align-middle w-6" class="align-middle"/>
                </div>
            </vs-button>
        </vx-tooltip>
    </div>
</template>

<script>
export default {
    name:'configurator-resource-button',  
    props: {
        properties: {type: Object,required: true}
    }, 
    methods:{
        sendToResources(){
            if (this.properties.isEditorUser) {
                this.$router.push({name: 'gallery-resources', params: {"uuid": this.$route.params.uuid, "versionUuid": this.properties.uuid}})
                return;
            }
            this.$router.push({name: 'resources', params: {"versionUuid": this.properties.uuid}});
        }
    },         

}
</script>
<style scoped>
.action-button button {
    @apply h-12 !important;
}
</style>
