var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DatatablePageLayout", {
    scopedSlots: _vm._u([
      {
        key: "actionButtons",
        fn: function() {
          return [
            _vm.userHasAdminAppsDrm && _vm.isAdminUser
              ? _c(
                  "vs-button",
                  {
                    staticClass: "xs:w-full sm:w-auto rounded-lg",
                    on: { click: _vm.createVersion }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("apps.configurator.createVersion")) +
                        " "
                    )
                  ]
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "datatable",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "bg-white p-8" },
              [
                _c(
                  "SKCollapse",
                  { ref: "collapse", attrs: { accordion: true } },
                  _vm._l(_vm.versionMajors, function(major, index) {
                    return _c("SKCollapseItem", {
                      key: major,
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "flex items-center" },
                                  [
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "text-lg font-medium" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.versionName(major, index)
                                            )
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "content",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "negative-margin" },
                                  [
                                    _c("Datatable", {
                                      attrs: {
                                        config: _vm.datatableConfig,
                                        elements:
                                          _vm.versionsGroupByMajor[major],
                                        stripe: true,
                                        customActions: _vm.customActions,
                                        draggable: false,
                                        sst: true
                                      },
                                      on: { action: _vm.action }
                                    })
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c("ModalInfo", {
              attrs: {
                modalOpen: _vm.modalOpen,
                type: _vm.modalType,
                headingText: _vm.headingModalText,
                text: _vm.modalText
              },
              on: {
                closedModal: function($event) {
                  _vm.modalOpen = false
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }