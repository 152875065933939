<template>
    <div>
        <vx-tooltip :text="$t('apps.configurator.release-button-tooltip')" position="top" delay=".3s" class="m-0">
            <vs-button type="border" class="mr-2 px-3 rounded-lg" @click="askReleaseVersion">
                <div class="flex flex-nowrap">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_598_22207)">
                            <path
                                d="M18.841 11.2757C21.0668 9.05143 20.2447 1.9444 20.2447 1.9444C20.2447 1.9444 13.1959 1.08934 10.9222 3.36146C8.64932 5.63437 4.41409 13.0299 4.41409 13.0299L9.16548 17.7783C9.16548 17.7783 16.6152 13.5015 18.841 11.2757Z"
                                stroke="#3560DA" stroke-width="1.86023" stroke-miterlimit="10" stroke-linejoin="round" />
                            <path
                                d="M13.125 9.07475C13.7809 9.73057 14.8442 9.73057 15.5 9.07475C16.1558 8.41893 16.1558 7.35563 15.5 6.6998C14.8442 6.04398 13.7808 6.04398 13.125 6.6998C12.4692 7.35563 12.4692 8.41893 13.125 9.07475Z"
                                fill="#3560DA" />
                            <path
                                d="M7.9791 17.3869C6.49594 18.8701 4.01848 18.1762 4.01848 18.1762C4.01848 18.1762 3.34241 15.6904 4.8125 14.2203"
                                stroke="#3560DA" stroke-width="1.86023" stroke-miterlimit="10" />
                            <path
                                d="M4.98859 12.4611L1.68501 11.8846L3.37963 9.3959C4.02737 8.44422 5.15914 7.93973 6.25911 8.11113L7.62415 8.32398"
                                stroke="#3560DA" stroke-width="1.86023" stroke-miterlimit="10" stroke-linejoin="round" />
                            <path
                                d="M9.73913 17.2109L10.3156 20.5145L12.8044 18.8199C13.756 18.1722 14.2605 17.0404 14.0891 15.9404L13.8763 14.5754"
                                stroke="#3560DA" stroke-width="1.86023" stroke-miterlimit="10" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_598_22207">
                                <rect width="22" height="22" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </vs-button>
        </vx-tooltip>

        <ModalInfo :modalOpen=modalOpen :type=modalType :headingText=headingModalText :text=modalText
            @closedModal="modalOpen = false" />
    </div>
</template>
<script>
import ConfiguratorVersionService from '@/modules/Apps/Services/Configurator/ConfiguratorVersionService'
import ModalInfo from '@/modules/Shared/Components/modal/ModalInfo.vue'
import loader from "@/modules/Shared/Mixins/loader";

export default {
    name: 'configurator-release-button',
    mixins: [
        loader
    ],
    components: {
        ModalInfo
    },
    props: {
        properties: { type: Object, required: true }
    },
    data() {
        return {
            modalOpen: false,
            headingModalText: "",
            modalText: "",
            modalType: "",
        }
    },
    methods: {
        async askReleaseVersion() {
            this.$vs.dialog({
                type:'confirm',
                color:'primary',
                title: this.$t("question.confirm"),
                text: this.$t("question.sure"),
                accept: () => this.releaseVersion()
            })
        },
        async releaseVersion() {
            let that = this
            this.loadAndNotifyError(async function () {
                let checkResponse = await ConfiguratorVersionService.checkStatusVersion(that.properties.uuid)
                if (checkResponse.status_code !== 200 || checkResponse.description.status === 'ERROR') {
                    that.modalType = 'danger'
                    that.headingModalText = that.$t("apps.configurator.version.release.ko")
                    that.modalText = Object.entries(checkResponse.description.errors).reduce((acc, error) => {
                        return `${acc}\n ${error[1]}`
                    }, "")
                    that.modalOpen = true
                    return
                }
                let response = await ConfiguratorVersionService.releaseVersion(that.properties.uuid)
                if (response.status_code == 201) {    
                    that.modalType = "success"
                    that.headingModalText = that.$t("apps.configurator.version.release.ok")
                    that.modalText = ''
                } else {
                    that.modalType = 'danger'
                    that.headingModalText = that.$t("apps.configurator.version.release.ko")
                    that.modalText = Object.entries(response.description).reduce((acc, error) => {
                        return `${acc}\n ${error[1]}`
                    }, "")
                }
                that.modalOpen = true
            })
        }
    }
}
</script>

<style scoped>
.action-button button {
    @apply h-12 !important;
}</style>
