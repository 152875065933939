<template>
    <div>
        <vx-tooltip :text="$t('apps.configurator.layout-button-tooltip')" position="top" delay=".3s" class="m-0">
            <vs-button type="border" class="mr-2 px-3 rounded-lg" @click="sendToHomeLayoutList">
                <div class="flex flex-nowrap">
                    <feather-icon icon="LayoutIcon" svgClasses="text-primary align-middle w-6" class="align-middle"/>
                </div>
            </vs-button>
        </vx-tooltip>
    </div>
</template>

<script>
export default {
    name:'configurator-layout-button',  
    props: {
        properties: {type: Object,required: true}
    }, 
    methods:{
        sendToHomeLayoutList(){
            this.$router.push({name: 'Layout-List', params: {"versionUuid": this.properties.uuid}});
        }
    },         

}
</script>

<style scoped>
.action-button button {
    @apply h-12 !important;
}
</style>